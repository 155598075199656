import { Container, Card, Carousel } from "react-bootstrap"

//username: test
//password: dGVzdA==

function Welcome() {
  return (
    <Container className="d-flex h-100 justify-content-center align-items-center">
      <Card>
        <Card.Header>
          <div style={{ textAlign: 'center' }}><h4>Welcome to the scope 3 demo app</h4></div>
        </Card.Header>
        <Card.Body>
          <Carousel variant="dark" controls={false}>
            <Carousel.Item interval={7000}>
              <img className="w-100" src="img/guidance/guidance.001.png" alt="" />
            </Carousel.Item>
            <Carousel.Item interval={7000}>
              <img className="w-100" src="img/guidance/guidance.002.png" alt="" />
            </Carousel.Item>
            <Carousel.Item interval={7000}>
              <img className="w-100" src="img/guidance/guidance.003.png" alt="" />
            </Carousel.Item>
            <Carousel.Item interval={7000}>
              <img className="w-100" src="img/guidance/guidance.004.png" alt="" />
            </Carousel.Item>
          </Carousel>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default Welcome;