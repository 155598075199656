import { useCallback, useState } from 'react';
import './ProjectEdit.css'
import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
} from 'reactflow';
import { Button, Modal, Form, InputGroup, Card, Nav } from 'react-bootstrap';
import 'reactflow/dist/style.css';
import {
  getNodes,
  getEdges,
  addSubNode,
  getNodeProperty,
  updateNodeName,
  setNodeProperty,
  ghgIdentifiers,
  energyIdentifiers
} from "./data_management"
import { getAR4Equivalent } from "./data_analysis"


function ProjectEdit() {
  const controlElements = ["ar4", ...ghgIdentifiers, ...energyIdentifiers, "requirements"];
  function getInitialProperties() {
    let initialProperties = {};
    for (const element of controlElements) {
      initialProperties[element] = (element === "requirements") ? "" : "0";
    }
    return initialProperties;
  }
  function getActualProperties(nodeId) {
    let actualProperties = {};
    for (const element of controlElements) {
      let nodeProperty = getNodeProperty(nodeId, element);
      if (element === "requirements") {
        actualProperties[element] = nodeProperty === null ? "" : nodeProperty.value;
      }
      else if (element === "ar4"){
        actualProperties[element] = getAR4Equivalent(nodeId).toString();
      }
      else {
        actualProperties[element] = nodeProperty === null ? "0" : nodeProperty.value.toString();
      }
    }
    return actualProperties;
  }
  function getFormControlId(controlElement) {
    return "formControlId" + controlElement;
  }

  const [nodes, setNodes, onNodesChange] = useNodesState(getNodes());
  const [edges, setEdges, onEdgesChange] = useEdgesState(getEdges());
  const [modalShow, setModalShow] = useState(false);
  const [modalNode, setModalNode] = useState(null);
  const [modalNodeName, setModalNodeName] = useState("dummy");
  const [modalElementProperties, setModalElementProperties] = useState(getInitialProperties());

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);
  function onNodeClick(event, node) {
    setModalNode(node);
    setModalNodeName(node.data.label);
    setmodalActiveTab("ghg");
    let actualProperties = getActualProperties(node.id);
    setModalElementProperties(actualProperties);
    setModalShow(true);
  }

  function onAddSubcomponentClick() {
    /* TBD remove node property */
    addSubNode(modalNode.id, modalNode.data.label + " - sub");
    setNodes([...getNodes()]);
    setEdges([...getEdges()]);
    setModalShow(false);
  }

  const tabGroupIdGHG = "tabGroupIdGHG";
  const tabGroupIdEnergy = "tabGroupIdEnergy";
  const tabGroupIdRequirements = "tabGroupIdRequirements";
  const formControlIdNodeName = "formControlIdNodeName";

  const tabGroupGHGDefaultStyle = "block";
  const tabGroupEnergyDefaultStyle = "none";
  const tabGroupRequirementsDefaultStyle = "none";



  function onComponentSaveClick() {
    const componentName = document.getElementById(formControlIdNodeName).value;
    updateNodeName(modalNode.id, componentName);
    for (const element of controlElements) {
      let elementValue = document.getElementById(getFormControlId(element)).value;
      if (element === "requirements") {
        if (elementValue !== "") {
          setNodeProperty(modalNode.id, element, elementValue);
        }
      }
      else {
        if (elementValue !== "0") {
          setNodeProperty(modalNode.id, element, parseFloat(elementValue));
        }
      }
    }
    setNodes([...getNodes()]);
    setEdges([...getEdges()]);
    setModalShow(false);
  }

  function setmodalActiveTab(tab) {
    const gasesDiv = document.getElementById(tabGroupIdGHG);
    const energyDiv = document.getElementById(tabGroupIdEnergy);
    const requirementsDiv = document.getElementById(tabGroupIdRequirements);

    if ((gasesDiv !== null) && (energyDiv !== null) && (requirementsDiv !== null)) {
      if (tab === "ghg") {
        gasesDiv.style.display = "block";
        energyDiv.style.display = "none";
        requirementsDiv.style.display = "none";
      }
      else if (tab === "energy") {
        gasesDiv.style.display = "none";
        energyDiv.style.display = "block";
        requirementsDiv.style.display = "none";
      }
      else if (tab === "requirements") {
        gasesDiv.style.display = "none";
        energyDiv.style.display = "none";
        requirementsDiv.style.display = "block";
      }
    }
  }

  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <Form.Control
              type="text"
              className=""
              id={formControlIdNodeName}
              defaultValue={modalNodeName}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='margin-bottom-btn'>
            <Button onClick={onAddSubcomponentClick}>add subcomponent</Button>
            <Button className='margin-left-btn' disabled>delete component</Button>
          </div>
          <Card>
            <Card.Header>
              <Nav variant="tabs" defaultActiveKey="#GHG">
                <Nav.Item>
                  <Nav.Link href="#GHG" onClick={() => setmodalActiveTab("ghg")}>GHG</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="#Energy" onClick={() => setmodalActiveTab("energy")}>Energy</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="#Requirements" onClick={() => setmodalActiveTab("requirements")}>Requirements</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body>
              <div id={tabGroupIdGHG} style={{ display: tabGroupGHGDefaultStyle }}>
                <InputGroup className="mb-3 margin-top-btn">
                  <InputGroup.Text id="basic-addon1">Carbon dioxide (CO2): </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className=""
                    id={getFormControlId("co2")}
                    defaultValue={modalElementProperties["co2"]}
                  />
                  <InputGroup.Text>kg</InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3 margin-top-btn">
                  <InputGroup.Text id="basic-addon1">Methane (CH4): </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className=""
                    id={getFormControlId("ch4")}
                    defaultValue={modalElementProperties["ch4"]}
                  />
                  <InputGroup.Text>kg</InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3 margin-top-btn">
                  <InputGroup.Text id="basic-addon1">Nitrous oxide (N2O): </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className=""
                    id={getFormControlId("n2o")}
                    defaultValue={modalElementProperties["n2o"]}
                  />
                  <InputGroup.Text>kg</InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3 margin-top-btn">
                  <InputGroup.Text id="basic-addon1">Nitrogen trifluoride (NF3): </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className=""
                    id={getFormControlId("nf3")}
                    defaultValue={modalElementProperties["nf3"]}
                  />
                  <InputGroup.Text>kg</InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3 margin-top-btn">
                  <InputGroup.Text id="basic-addon1">Sulfur hexafluoride (SF6): </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className=""
                    id={getFormControlId("sf6")}
                    defaultValue={modalElementProperties["sf6"]}
                  />
                  <InputGroup.Text>kg</InputGroup.Text>
                </InputGroup>
              </div>
              <div id={tabGroupIdEnergy} style={{ display: tabGroupEnergyDefaultStyle }}>
                <InputGroup className="mb-3 margin-top-btn">
                  <InputGroup.Text id="basic-addon1">Electrical: </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className=""
                    id={getFormControlId("electrical")}
                    defaultValue={modalElementProperties["electrical"]}
                  />
                  <InputGroup.Text>kWh</InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3 margin-top-btn">
                  <InputGroup.Text id="basic-addon1">Thermal: </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className=""
                    id={getFormControlId("thermal")}
                    defaultValue={modalElementProperties["thermal"]}
                  />
                  <InputGroup.Text>mmBTU</InputGroup.Text>
                </InputGroup>
                <InputGroup className="mb-3 margin-top-btn">
                  <InputGroup.Text id="basic-addon1">SI: </InputGroup.Text>
                  <Form.Control
                    type="text"
                    className=""
                    id={getFormControlId("si")}
                    defaultValue={modalElementProperties["si"]}
                  />
                  <InputGroup.Text>MJ</InputGroup.Text>
                </InputGroup>
              </div>
              <div id={tabGroupIdRequirements} style={{ display: tabGroupRequirementsDefaultStyle }}>
                <Form.Control
                  as="textarea"
                  className=""
                  id={getFormControlId("requirements")}
                  rows={5}
                  defaultValue={modalElementProperties["requirements"]}
                />
              </div>
            </Card.Body>
          </Card>
          <InputGroup className="mb-2 mt-2 margin-top-btn">
            <InputGroup.Text id="basic-addon1">AR4 CO2 equivalent: </InputGroup.Text>
            <Form.Control
              type="text"
              className=""
              disabled={true}
              id={getFormControlId("ar4")}
              defaultValue={modalElementProperties["ar4"]}
            />
            <InputGroup.Text>kg</InputGroup.Text>
          </InputGroup>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
          <Button onClick={onComponentSaveClick}>Save</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeClick={onNodeClick}
      >
        <Background />
      </ReactFlow>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)} />
    </>
  );
}

export default ProjectEdit;