import React from 'react';
import { Page, Text, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';

// Create styles
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

//{items.map((item,index)=>{ return <li key={index}>{item}</li> })}

// Create Document Component
function MyDocument ({chapters}) {

  const chapterList = []
  chapters.forEach((chapter, index)=>{
    chapterList.push( <><Text style={styles.subtitle}>{chapter["title"]}</Text> <Text style={styles.text}>{chapter["text"]}</Text></> )
  })

  return (
  <Document>
    <Page style={styles.body}>
      <Text style={styles.header} fixed>Copyright Climate Proof 2023</Text>
      <Text style={styles.title}>ESG Report</Text>
      <Text style={styles.author}>Automatisiert erstellt durch ESG Reporter App</Text>
      <Image
        style={styles.image}
        src="/img/logo_climate_proof.png"
      />

      {chapterList}

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
    </Page>
  </Document>
  );
}

export default MyDocument;
