import { useState, useEffect } from "react";
import { Form, Button, ProgressBar, Card } from "react-bootstrap";
import '../../index.css'
import { Tooltip } from 'react-tooltip'


function QuestionHandler({ questions, onSubmitClick }) {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [radioState, setRadioState] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [textContent, setTextContent] = useState(questions[questionIndex].answer);

  const questionsLen = questions.length;
  const minQuestionIndex = 0;
  const maxQuestionIndex = questionsLen - 1;

  const onRadioYesClick = () => {
    questions[questionIndex].answer = true;
    setRadioState(true)
  }

  const onRadioNoClick = () => {
    questions[questionIndex].answer = false;
    setRadioState(false)
  }

  function onPreviousClick() {
    const nextIndex = questionIndex - 1;
    const nextProgress = (nextIndex / questionsLen) * 100;

    if (nextIndex >= minQuestionIndex) {
      if (questions[nextIndex].type === "binary") {
        setRadioState(questions[nextIndex].answer);
      }
      setQuestionIndex(nextIndex);
      setProgress(nextProgress);
      setSubmitDisabled(true);
    }
  }

  function onNextClick() {
    const nextIndex = questionIndex + 1;
    const nextProgress = (nextIndex / questionsLen) * 100;
    if (nextIndex <= maxQuestionIndex) {
      if (questions[nextIndex].type === "binary") {
        setRadioState(questions[nextIndex].answer);
      }
      setQuestionIndex(nextIndex);
      setProgress(nextProgress);
    }
    if (nextIndex === questionsLen - 1) {
      setSubmitDisabled(false);
    }
  }


  function onPreSubmitClick() {
    onSubmitClick();
  }


  const handleTextChange = (event) => {
    setTextContent(event.target.value);
    questions[questionIndex].answer = event.target.value;
  };
  useEffect(() => {
    setTextContent(questions[questionIndex].answer);
  }, [questions, questionIndex]);


  useEffect(() => {
    if (questions[questionIndex].type === "binary") {
      setRadioState(questions[questionIndex].answer);
    }
  }, [questions, questionIndex]);

  return (
    <>
      <Card>
        <Card.Header>
          <div style={{ textAlign: 'center' }}><h2>{questions[questionIndex].topic}</h2></div>
        </Card.Header>
        <Card.Body>
          <Form>
            {questions[questionIndex].type === "text" && (
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label><h5>{questions[questionIndex].question} <a href="#/" data-tooltip-id="text-tooltip" data-tooltip-html={questions[questionIndex].tip} data-tooltip-place="top">
                  <i className="bi bi-info-circle"></i></a></h5>
                </Form.Label>
                <Tooltip id="text-tooltip" />

                <Form.Control
                  as="textarea"
                  keyChange={null}
                  keyWrite={null}
                  value={textContent}
                  onChange={handleTextChange}
                  rows={3}
                />
              </Form.Group>
            )}
            {questions[questionIndex].type === "binary" && (
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label><h5>{questions[questionIndex].question}     <a href="#/" data-tooltip-id="binary-tooltip" data-tooltip-html={questions[questionIndex].tip} data-tooltip-place="top">
                  <i className="bi bi-info-circle"></i></a></h5>
                </Form.Label>
                <Tooltip id="binary-tooltip" />
                <Form.Check type="radio" label="Ja" checked={radioState} onClick={onRadioYesClick} name="group1" id={`inline-radio-yes`} />
                <Form.Check type="radio" label="Nein" checked={!radioState} onClick={onRadioNoClick} name="group1" id={`inline-radio-no`} />
              </Form.Group>
            )}

            <Button onClick={onPreviousClick} variant="primary"  >Previous</Button>
            <Button onClick={onNextClick} className="btn-margin-left" variant="primary">Next</Button>
            <Button onClick={onPreSubmitClick} className="btn-margin-left" disabled={submitDisabled} variant="primary">Submit</Button>
          </Form>
          <ProgressBar now={progress} className="bar-margin-up" />
        </Card.Body>
      </Card>
    </>
  );
}

export default QuestionHandler;
