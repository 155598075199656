import React from "react";
import { useCallback, useState, useEffect } from 'react';
import { Chart } from "react-google-charts";
import { Row, Button, Modal, Form } from 'react-bootstrap';
import 'reactflow/dist/style.css';
import { getNodes, getNodeName } from "./data_management"
import { 
  getTotalAR4Emission, 
  getTopAR4Emission,
  getAR4Equivalent, 
  nodeHasEmission 
} from "./data_analysis"

export const pieOptions = {
  title: "My co2 equivalent emissions",
  backgroundColor: "transparent"
};
export const barOptions = {
  title: "My top equivalent emissions",
  backgroundColor: "transparent"
};

function ProjectEval() {
  const [pieData, setPieData] = useState([["component_name", "co2_equivalent"]]);
  const [barData, setBarData] = useState([["component_name", "co2_equivalent"]]);

  useEffect(() => {
    let newPieData = [["component_name", "co2_equivalent"]];
    let newBarData = [["component_name", "co2_equivalent"]];
    for (const node of getNodes()){
      if (nodeHasEmission(node.id)){
        newPieData.push([getNodeName(node.id), getAR4Equivalent(node.id)]);
      }
    }

    for (const element of getTopAR4Emission(5)){
      newBarData.push([element.name, element.value]);
    }
    
    setPieData(newPieData);
    setBarData(newBarData);
  }, []);

  return (
    <>
      <Row>
      <div style={{ textAlign: 'center' }}><h2>Project Evaluation</h2></div>
      </Row>
      <Row>
        <Chart
        chartType="PieChart"
        data={pieData}
        options={pieOptions}
        width={"100%"}
        height={"400px"}
        />
      </Row>

      <Row>
        <Chart
        chartType="BarChart"
        data={barData}
        options={barOptions}
        width={"100%"}
        height={"400px"}
        />
      </Row>
      
    </>
  );
}

export default ProjectEval;

