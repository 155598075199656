import {Container, Navbar, NavDropdown} from "react-bootstrap"


function Header ({loggedInUser, setLoggedInUser}) {
  function onLogoutClick(){
    setLoggedInUser(null)
  }

  return (
    <Navbar className="bg-light">
      <Container>
        <Navbar.Brand>
          <img
            alt=""
            src="/img/Climate Proof Logo 1Logo BC.svg"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          ESG Reporter App
        </Navbar.Brand>

        <NavDropdown className="justify-content-end" title={<div style={{display: "inline-block"}}><i className="bi bi-person-circle"></i> {loggedInUser}</div>}>
          <NavDropdown.Item href="#"><i className="bi bi-person-gear"></i> Preferences</NavDropdown.Item>
            <NavDropdown.Divider />
          <NavDropdown.Item href="#" onClick={onLogoutClick}><i className="bi bi-person-slash"></i> Logout</NavDropdown.Item>
        </NavDropdown>
      </Container>
    </Navbar>
  );
}

export default Header;
