import {getNodes, getEdges, getProperties, getNodeName} from "./data_management"

function nodeIsSource(nodeId){
    const edges = getEdges();
    let isSource = false;

    for (const edge of edges){
        if (edge.source === nodeId){
            isSource = true;
            break;
        }
    }
    return isSource;
}
function nodeIsTarget(nodeId){
    const edges = getEdges();
    let isTarget = false;

    for (const edge of edges){
        if (edge.target === nodeId){
            isTarget = true;
            break;
        }
    }
    return isTarget;
}

function getLeaveIds(){
    const nodes = getNodes();
    let leaveIds = [];

    for (const node of nodes){
        if (!nodeIsSource(node.id)){
            leaveIds.push(node.id);
        }
    }
    return leaveIds;
}
function getRootId(){
    const nodes = getNodes();
    let rootId = null;

    for (const node of nodes){
        if (!nodeIsTarget(node.id)){
            rootId = node.id;
            break;
        }
    }
    return rootId;
}
function getSourceIdFromTargetId(targetId){
    const edges = getEdges();
    let sourceId = null;

    for (const edge of edges){
        if (edge.target === targetId){
            sourceId = edge.source;
            break;
        }
    }
    return sourceId;
}

export function getMaxLevel(){
    const nodes = getNodes();
    const edges = getEdges();
    let maxLevel = 1;
    if (edges.length !== 0){
        let rootId = getRootId(nodes, edges);
        let leaveIds = getLeaveIds(nodes, edges);

        for (const leaveId of leaveIds){
            let tempLevel = 2;
            let targetId = leaveId;
            let sourceId = getSourceIdFromTargetId(targetId);

            maxLevel = tempLevel;
            while(sourceId !== rootId){
                tempLevel++;
                if (tempLevel > maxLevel){
                    maxLevel = tempLevel;
                }
                targetId = sourceId;
                sourceId = getSourceIdFromTargetId(targetId);
            }
        }
    }

    return maxLevel; 
}
export function getNodeLevel(nodeId){ 
    const nodes = getNodes();
    const edges = getEdges();
    let nodeLevel = 1;
    let rootId = getRootId(nodes, edges);

    if (nodeId !== rootId){
        let targetId = nodeId;
        let sourceId = getSourceIdFromTargetId(targetId);

        nodeLevel = 2;
        while(sourceId !== rootId){
            nodeLevel++;
            targetId = sourceId;
            sourceId = getSourceIdFromTargetId(targetId);
        }
    }

    return nodeLevel; 
}

export function getNodeChildren(nodeId){ 
    const edges = getEdges();
    let nodeChildren = 0;

    for (const edge of edges){
        if (edge.source === nodeId){
            nodeChildren++;
        }
    }
    return nodeChildren; 
}


export function getAR4Equivalent(nodeId){
    const properties = getProperties();
    let ar4Equivalent = 0;

    for (const property of properties){
        if (property.node === nodeId){
            if (property.myType === "co2"){
                ar4Equivalent += property.value;
            }
            else if (property.myType === "ch4"){
                ar4Equivalent += (property.value * 25);
            }
            else if (property.myType === "n2o"){
                ar4Equivalent += (property.value * 298);
            }
            else if (property.myType === "nf3"){
                ar4Equivalent += (property.value * 17200);
            }
            else if (property.myType === "sf6"){
                ar4Equivalent += (property.value * 22800);
            }
            else if (property.myType === "electrical"){
                ar4Equivalent += (property.value * 0.72869);    /* eGrid Subregion Germany */
            }
            else if (property.myType === "thermal"){
                ar4Equivalent += (property.value * 53.06);     /* Fuel Type Natural Gas */
            }
            else if (property.myType === "si"){
                ar4Equivalent += (property.value * 0);
            }
        }
    }
    return ar4Equivalent;
}
export function getTotalAR4Emission(){
    const nodes = getNodes();
    let totalAR4Emission = 0;

    for (const node of nodes){
        totalAR4Emission += getAR4Equivalent(node.id);
    }
    return totalAR4Emission;
}
export function getTopAR4Emission(maxNumber){
    let emissionArray = [];

    for (const node of getNodes()){
        if (nodeHasEmission(node.id)){
            emissionArray.push({name:getNodeName(node.id), value:getAR4Equivalent(node.id)});
        }
    }
    emissionArray.sort((a,b) => (a.value >= b.value) ? 1 : ((b.value > a.value) ? -1 : 0));
    return emissionArray;
}
export function nodeHasEmission(nodeId){
    const properties = getProperties();
    let hasEmission = false;

    for (const property of properties){
        if ((property.node === nodeId) && (property.myType !== "requirements")){
            hasEmission = true;
            break;
        }
    }
    return hasEmission;
}
