import {Container, Col, Row, Button, Form, Card} from "react-bootstrap"

import '../index.css'

function Login ({setLoggedInUser}) {
  const formControlIdUsername = "formControlIdUsername";
  const formControlIdPassword = "formControlIdPassword";
  function onLoginClick(){
    const username = document.getElementById(formControlIdUsername).value;
    const password = document.getElementById(formControlIdPassword).value;
    let expectedPassword = btoa(username) //crypto.subtle.digest("SHA-256", username)
    if (password === expectedPassword){
      setLoggedInUser(username);
    }
    else{
      alert("Invalid password")
    }
    
  }

  return (
    <>
    <Row className="h-100" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Col xxs={10} xs={10} sm={10} md={8} lg={6} xl={5} xxl={5}>
        <Card>
          <Card.Header>
            <div style={{ textAlign: 'center' }}><h2>User login</h2></div>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Username</Form.Label>
                <Form.Control type="email" placeholder="Enter username" id={formControlIdUsername}/>
                <Form.Text className="text-muted">
                  We'll never share your username with anyone else.
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" id={formControlIdPassword}/>
              </Form.Group>
              <Button variant="primary" href="https://www.climate-proof.net/privacy">Privacy</Button>
              <Button variant="primary" href="https://www.climate-proof.net/#Contact" className="btn-margin-left">Impressum</Button>
              <Button variant="primary" onClick={onLoginClick} style={{float: 'right'}}>
                Login
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    </>
  );
}

export default Login;