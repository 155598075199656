import dagre from 'dagre';
import {getNodeLevel, getNodeChildren} from "./data_analysis"

let nodes = [
  { id:'root', position: { x: 0, y: 0 }, data:{ label: "Demo Project" } },
  { id:'1', position: { x: 0, y: 0 }, data:{ label: "Component 1" } },
  { id:'11', position: { x: 0, y: 0 }, data:{ label: "Component 1.1" } },
  { id:'12', position: { x: 0, y: 0 }, data:{ label: "Component 1.2" } },
  { id:'2', position: { x: 0, y: 0 }, data:{ label: "Component 2" } },
  { id:'21', position: { x: 0, y: 0 }, data:{ label: "Component 2.1" } },
  { id:'22', position: { x: 0, y: 0 }, data:{ label: "Component 2.2" } },
  { id:'23', position: { x: 0, y: 0 }, data:{ label: "Component 2.3" } },
];
let edges = [
  { id:'root->1', source:'root', target:'1' },
  { id:'root->2', source:'root', target:'2' },
  { id:'1->11', source:'1', target:'11' },
  { id:'1->12', source:'1', target:'12' },
  { id:'2->21', source:'2', target:'21' },
  { id:'2->22', source:'2', target:'22' },
  { id:'2->23', source:'2', target:'23' },
];

let properties = []; //{ id:'root_prop', node:'root', myType:'co2', unit: null, value:21 }
export const ghgIdentifiers = ["ar4", "co2", "ch4", "n2o", "nf3", "sf6"];
export const energyIdentifiers = ["electrical", "thermal", "si"];

function getUniqueId(){ return Date.now().toString() }

export function getNodes(){ return nodes; }
export function getEdges(){ return edges; }
export function getProperties(){ return properties; }

export function addSubNode(sourceId, name){
  const targetId = getUniqueId()
  if (getNodeLevel(sourceId) >= 5){
    alert("Maximal number of levels reached in demo version");
  }
  else if (getNodeChildren(sourceId) >= 5){
    alert("Maximal number of children reached in demo version");
  }
  else{
    nodes.push({id:targetId, position: {x:0, y:0}, data: {label:name} });
    edges.push({id:sourceId+"->"+targetId, source:sourceId, target:targetId});
  
    layoutNodes();
  }
}

export function getNodeName(nodeId){
  let nodeName = ""
  for (const node of nodes) {
    if (node.id === nodeId) {
      nodeName = node.data.label;
      break;
    }
  }
  return nodeName
}
export function updateNodeName(nodeId, name){
  for (let i = 0; i < nodes.length; i++) {
    if (nodes[i].id === nodeId) {
      nodes[i].data.label = name;
      break;
    }
  }
}
export function getNodeProperty(nodeId, myType){
  let nodeProperty = null;
  
  for (let i = 0; i < properties.length; i++) {
    if ((properties[i].node === nodeId) && (properties[i].myType === myType)) {
      nodeProperty = properties[i];
      break;
    }
  }

  return nodeProperty;
}
export function setNodeProperty(nodeId, myType, value){
  let propertyFound = false;
  
  for (let i = 0; i < properties.length; i++) {
    if ((properties[i].node === nodeId) && (properties[i].myType === myType)) {
      properties[i].value = value;
      propertyFound = true;
      break;
    }
  }

  if (!propertyFound){
    properties.push({id:nodeId+"_prop_"+myType, node:nodeId, myType:myType, unit: null, value:value})
  }
}


const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

function layoutNodes(){
  const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, edges);
  nodes = layoutedNodes;
  edges = layoutedEdges;
}

//https://reactflow.dev/docs/examples/layout/dagre/
const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return { nodes, edges };
};

layoutNodes();  /* layout nodes on startup */
