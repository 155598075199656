import {Col, Nav} from "react-bootstrap"

function Sidebar ({setSelectedPage}) {
  function onWelcomeClick(){ setSelectedPage("welcome") }
  function onHomeClick(){ setSelectedPage("home") }
  function onTasksClick(){ setSelectedPage("tasks") }
  function onSettingsClick(){ setSelectedPage("settings") }
  function onReportClick(){ setSelectedPage("report") }
  function onProjectEvalClick(){ setSelectedPage("project_eval") }
  function onProjectEditClick(){ setSelectedPage("project_edit") }

  return (
    <Col xxs={3} xs={3} sm={3} md={3} lg={2} xl={2} xxl={2} className="h-100" style={{paddingLeft: 0}}>
    <Nav variant="tabs" className="flex-column bg-light h-100">
    <Nav.Link onClick={onWelcomeClick} className="text-black"><i className="bi bi-info-circle"></i> Welcome</Nav.Link>
      <Nav.Link onClick={onHomeClick} className="text-black"><i className="bi bi-house"></i> Home</Nav.Link>
      <Nav.Link onClick={onTasksClick} className="text-black"><i className="bi bi-list-task"></i> Tasks</Nav.Link>
      <Nav.Link onClick={onSettingsClick} className="text-black"><i className="bi bi-gear"></i> Settings</Nav.Link>
      <Nav.Link></Nav.Link>
      <Nav.Link onClick={onReportClick} className="text-black"><i className="bi bi-journal-check"></i> Report</Nav.Link>
      <Nav.Link className="text-black"><span onClick={onProjectEvalClick}><i className="bi bi-diagram-3"></i> Project</span> <i onClick={onProjectEditClick} className="bi bi-pencil"></i></Nav.Link>

      <div className="text-black position-absolute bottom-0">
      <Nav.Link className="text-black"  target="_blank" href="https://www.climate-proof.net/privacy">Privacy</Nav.Link>
      <Nav.Link className="text-black"  target="_blank" href="https://www.climate-proof.net/#Contact">Impressum</Nav.Link>
      </div>

      
    </Nav>
    </Col>
  );
}

export default Sidebar;