import { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Welcome from "./Welcome";
import Login from "./Login";
import Report from "./report/Report";
import ProjectEdit from "./project/ProjectEdit";
import ProjectEval from "./project/ProjectEval";
import { Container, Row, Col } from "react-bootstrap"


function App() {
  const defaultPage = "welcome"
  const [selectedPage, setSelectedPage] = useState(defaultPage);
  const [loggedInUser, setLoggedInUser] = useState(null);

  return (
    <Container fluid className="vh-100 d-flex flex-column">
      <Row>
        <Header loggedInUser={loggedInUser} setLoggedInUser={setLoggedInUser} />
      </Row>
      {loggedInUser === null && (
        <Login setLoggedInUser={setLoggedInUser} />
      )}
      {loggedInUser !== null && (
        <Row className="h-100">
          <Sidebar setSelectedPage={setSelectedPage} />
          <Col className="h-100">
            <Row className="h-100">
              {selectedPage === "welcome" && (
                <Welcome />
              )}
              {((selectedPage === "home") || (selectedPage === "tasks") || (selectedPage === "settings")) && (
                <div style={{ textAlign: 'center' }}><h2>Page element under construction</h2></div>
              )}
              {selectedPage === "report" && (
                <Report />
              )}
              {selectedPage === "project_eval" && (
                <ProjectEval />
              )}
              {selectedPage === "project_edit" && (
                <div style={{ height: "90vh" }}>
                  <ProjectEdit />
                </div>
              )}
            </Row>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default App;
