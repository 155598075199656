import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import '../../index.css'
import gri2Questions from './gri2Questions.json';
import sectorTopicsOverall from './sectorTopics.json';
import {getAiResponse} from "./openai_handler"
import { PDFDownloadLink } from '@react-pdf/renderer';
import MyDocument from "./MyDocument";
import QuestionHandler from "./QuestionHandler";


function Report () {
  const numberTopTopics = 10;
  const numberTopTopicQuestions = 3;
  const numberChapters = 2 + numberTopTopicQuestions;   /* gri2 + top question list + top griX question chapters */
  const [reportState, setReportState] = useState("pollGri2Questions");
  const [reportContent, setReportContent] = useState({"chapters":[]});
  const [intermediateStateInfo, setIntermediateStateInfo] = useState("");
  const [firstTopIndustryTopicsQuestions, setFirstTopIndustryTopicsQuestions] = useState(null);
  const [secondTopIndustryTopicsQuestions, setSecondTopIndustryTopicsQuestions] = useState(null);  
  const [thirdTopIndustryTopicsQuestions, setThirdTopIndustryTopicsQuestions] = useState(null);  
  const [topIndustryTopicsList, setTopIndustryTopicsList] = useState(null);

  const intermediateStates = ["assertion", "generateTopIndustryTopics", "generateIndustryTopicQuestions", "generatingReport"]


  /* ---------------> start service functions <--------------- */
  function assert(check, notification)
  {
    if(check === false)
    {
      setIntermediateStateInfo("Assertion: " + notification);
      setReportState("assertion");
    }
  }

  function getIndustrySector()
  {
    const gri2IndustrySectorIndex = 0;
    return gri2Questions[gri2IndustrySectorIndex].answer;
  }

  function getActivity()
  {
    /* Tätigkeitsfeld */
    const gri2ActivityIndex = 1;
    return gri2Questions[gri2ActivityIndex].answer;
  }

  function getIndustySectorTopicsList()
  {
    const industrySector = getIndustrySector();
    let topicsList = null;
    if (industrySector in sectorTopicsOverall)
    {
      topicsList = sectorTopicsOverall[industrySector];
    }
    else
    {
      topicsList = sectorTopicsOverall["Standard Topics (GRI X)"];
    }
    return topicsList;
  }  
  
  function getGri2ChapterRequest(){
    let request = "";
    //request += "Erstelle bitte einen Nachhaltigkeitsbericht auf Basis der folgenden Fragen und Antworten gemäß dem GRI Standard in einem in einem leicht lesbaren Format mit Einleitung, Hauptteil und Schlussfolgerung: \n"
    request += "Schreibe ein Kapitel eines Nachhaltigkeitsberichts auf Basis der folgenden Fragen und Antworten: \n"

    for (const questionElement of gri2Questions)
    {
      request += "Frage: " + questionElement["question"] + ", Antwort: " + questionElement["answer"] + "\n"
    }
    
    return request;
  }

  function getGriXChapterRequest(topIndustryTopicIndex){
    let request = "";
    //request += "Erstelle bitte einen Nachhaltigkeitsbericht auf Basis der folgenden Fragen und Antworten gemäß dem GRI Standard in einem in einem leicht lesbaren Format mit Einleitung, Hauptteil und Schlussfolgerung: \n"
    request += "Schreibe ein Kapitel eines Nachhaltigkeitsberichts auf Basis der folgenden Fragen und Antworten: \n"
    
    let questions = null;
    if (topIndustryTopicIndex === 0)
    {
      questions = firstTopIndustryTopicsQuestions;
    }
    else if (topIndustryTopicIndex === 1)
    {
      questions = secondTopIndustryTopicsQuestions;
    }
    else if (topIndustryTopicIndex === 2)
    {
      questions = thirdTopIndustryTopicsQuestions;
    }

    for (const questionElement of questions)
    {
      request += "Frage: " + questionElement["question"] + ", Antwort: " + questionElement["answer"] + "\n"
    }
    
    return request;
  }

  function getIndustryTopicsChapter(topIndustryTopicsList){
    let chapter = "";
    //chapter += "Wesentliche Themen des gewählten Industriesektors: \n"
    //for (const sectorTopic of getIndustySectorTopicsList())
    //{
    //  chapter += "\t- " + sectorTopic + "\n"
    //}
    //chapter += "\n"
    chapter += "Identifizierte Themen für Ihr Unternehmen: \n"
    for (const topTopic of topIndustryTopicsList)
    {
      chapter += "\t- " + topTopic + "\n"
    }

    return chapter;
  }

  function getTopIndustryTopicsRequest(){
    const industrySector = getIndustrySector();
    const activity = getActivity();
    let request = "";
    //request += "Gebe mir eine JSON Liste ohne keys der " + numberTopTopics + " Themen mit den gravierensten Auswirkungen aufgrund der bisherigen GRI Berichtserstattung für das Tätigkeitsfeld " + activity + " im Industrisektor " + industrySector + " auf Basis der folgenden Themen: ";
    //request += "\nThemenliste:\n";
    //for (const sectorTopic of getIndustySectorTopicsList())
    //{
    //  request += sectorTopic + "\n"
    //}
    
    request += "Erstelle eine JSON Liste ohne keys der relevantesten Nachhaltigkeitsthemen für eine " + activity + " im Industriesektor " + industrySector + ". \n";
    
    return request;
  }

  function getIndustryTopicQuestionRequest(topic){
    const request = "Erstelle mir eine Liste mit Fragen und Erläuterungen und Antworten gemäß dem GRI Standard im JSON Format (Liste von Objekten mit den keys question, tip und answer) für das Thema " + topic + ". In den Antworten bitte jeweils 3 branchenübliche Massnahmen zur Verbesserung des ESG-Ratings mit jeweils 3 Unterpunkten.";
    return request;
  }


  let realtypeof = function (obj) {
    switch (typeof(obj)) {
        case 'object':
            if (obj instanceof Array)
                return 'array';
            if (obj instanceof Date)
                return 'date';
            if (obj instanceof RegExp)
                return 'regexp';
            if (obj instanceof String)
                return 'string]';
            if (obj instanceof Number)
                return 'number';

            return 'object';
        default:
            return typeof(obj);
    }   
  };
  function getReturnList(result){
    let retVal = null;
    if (realtypeof(result) === "array"){
      retVal = result;
    }
    else if(realtypeof(result) === "object"){
      const firstVal = result[Object.keys(result)[0]];
      if (realtypeof(firstVal) === "array"){
        retVal = firstVal;
      }
    }
    return retVal;
  }

  /* ---------------> end service functions <--------------- */

  /* ---------------> start state machine functions <--------------- */
  function generateIndustryTopicQuestions(topIndustryTopicIndex)
  {
    setIntermediateStateInfo("Generating Topic Questions " + topIndustryTopicsList[topIndustryTopicIndex] + "...");
    setReportState("generateIndustryTopicQuestions");
    const request = getIndustryTopicQuestionRequest(topIndustryTopicsList[topIndustryTopicIndex]);
    const response = getAiResponse(request);
    response.then((res) => {
      const jsonResponse = JSON.parse(res);
      const intermediateTopIndustryTopicsQuestions = getReturnList(jsonResponse);
      assert(intermediateTopIndustryTopicsQuestions.length !== 0, "invalid topic question length");
      for (var i = 0; i < intermediateTopIndustryTopicsQuestions.length; i++) 
      { 
        intermediateTopIndustryTopicsQuestions[i]["topic"] = topIndustryTopicsList[topIndustryTopicIndex];
        intermediateTopIndustryTopicsQuestions[i]["type"] = "text";
        //intermediateTopIndustryTopicsQuestions[i]["answer"] = null;
      }

      if (topIndustryTopicIndex === 0)
      {
        setFirstTopIndustryTopicsQuestions(intermediateTopIndustryTopicsQuestions);
      }
      else if (topIndustryTopicIndex === 1)
      {
        setSecondTopIndustryTopicsQuestions(intermediateTopIndustryTopicsQuestions);
      }
      else if (topIndustryTopicIndex === 2)
      {
        setThirdTopIndustryTopicsQuestions(intermediateTopIndustryTopicsQuestions);
      }
    });
  }
  
  function generateTopIndustryTopics()
  {
    setIntermediateStateInfo("Generating Top GRI X Industry Topics ...");
    setReportState("generateTopIndustryTopics");

    const request = getTopIndustryTopicsRequest();
    const response = getAiResponse(request);
    response.then((res) => {
      const intermediateTopIndustryTopicsList = JSON.parse(res);
      assert(intermediateTopIndustryTopicsList.length === numberTopTopics, "invalid top industry length");
      const intemediateReportContent = reportContent;
      intemediateReportContent["chapters"].push({"title": "Übersicht der wesentlichen Themen des gewählten Industriesektors", "text": getIndustryTopicsChapter(intermediateTopIndustryTopicsList)});
      setTopIndustryTopicsList(intermediateTopIndustryTopicsList);
    });
  }
  
  function generateGri2Chapter(){
    const request = getGri2ChapterRequest();
    const response = getAiResponse(request);
    response.then((res) => {
      const intemediateReportContent = reportContent;
      intemediateReportContent["chapters"].push({"title": "GRI2", "text": res});
      setReportContent(intemediateReportContent);
    });
    generateTopIndustryTopics();
  }

  function generateGriXChapter(topIndustryTopicIndex){
    const request = getGriXChapterRequest(topIndustryTopicIndex);
    const response = getAiResponse(request);
    response.then((res) => {
      const intemediateReportContent = reportContent;
      intemediateReportContent["chapters"].push({"title": topIndustryTopicsList[topIndustryTopicIndex], "text": res});
      setReportContent(intemediateReportContent);
      if (reportContent["chapters"].length === numberChapters) {
        setReportState("generatedReport");
      }
    });
  }


  function onQuestionSubmitClick(){
    if (reportState === "pollGri2Questions")
    {
      generateGri2Chapter();
    }
    else if (reportState === "pollFirstIndustryTopicQuestions")
    {
      generateIndustryTopicQuestions(1);
      generateGriXChapter(0);
    }
    else if (reportState === "pollSecondIndustryTopicQuestions")
    {
      generateIndustryTopicQuestions(2);
      generateGriXChapter(1);
    }
    else if (reportState === "pollThirdIndustryTopicQuestions")
    {
      generateGriXChapter(2);
      setIntermediateStateInfo("Generating ESG Report ...");
      setReportState("generatingReport");
    }
    /* <div style={{ textAlign: 'center' }}><h2>{reportHeader}</h2><p>{reportChapter}</p></div> */
  }

  useEffect(() => {
    if (firstTopIndustryTopicsQuestions !== null) {
      setReportState("pollFirstIndustryTopicQuestions");
    }
  }, [firstTopIndustryTopicsQuestions]);
  useEffect(() => {
    if (secondTopIndustryTopicsQuestions !== null) {
      setReportState("pollSecondIndustryTopicQuestions");
    }
  }, [secondTopIndustryTopicsQuestions]);
  useEffect(() => {
    if (thirdTopIndustryTopicsQuestions !== null) {
      setReportState("pollThirdIndustryTopicQuestions");
    }
  }, [thirdTopIndustryTopicsQuestions]);
  useEffect(() => {
    if (topIndustryTopicsList !== null) {
      generateIndustryTopicQuestions(0);
    }
  }, [topIndustryTopicsList]);
  /*
  useEffect(() => {
    console.log("Chapter length: " + reportContent["chapters"].length);
    if (reportContent["chapters"].length === (numberTopTopicQuestions+1)) {
      setReportState("generatedReport");
    }
  }, [reportContent]);
  */
  /* ---------------> end state machine functions <--------------- */

  return (
    <>
    <Row className="h-100" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Col xxs={10} xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
        {reportState === "pollGri2Questions" && (
            <QuestionHandler questions={gri2Questions} onSubmitClick={onQuestionSubmitClick} />
        )}
        {reportState === "pollFirstIndustryTopicQuestions" && (
            <QuestionHandler questions={firstTopIndustryTopicsQuestions} onSubmitClick={onQuestionSubmitClick} />
        )}
        {reportState === "pollSecondIndustryTopicQuestions" && (
            <QuestionHandler questions={secondTopIndustryTopicsQuestions} onSubmitClick={onQuestionSubmitClick} />
        )}
        {reportState === "pollThirdIndustryTopicQuestions" && (
            <QuestionHandler questions={thirdTopIndustryTopicsQuestions} onSubmitClick={onQuestionSubmitClick} />
        )}
        {intermediateStates.includes(reportState) && (
          <div style={{ textAlign: 'center' }}><h2>{intermediateStateInfo}</h2></div>
        )}
        {reportState === "generatedReport" && (
          <div style={{ textAlign: 'center' }}><h2>Generated ESG report:</h2>
            <PDFDownloadLink document={<MyDocument chapters={reportContent["chapters"]} />} fileName="your_esg_report.pdf">
              {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download ESG report')}
            </PDFDownloadLink>
        </div>
        )}
      </Col>
    </Row>
    </>
  );
}

export default Report;