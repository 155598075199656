
import OpenAI from 'openai';

// Optional, but recommended: run on the edge runtime.
// See https://vercel.com/docs/concepts/functions/edge-functions
export const runtime = 'edge';

const openai = new OpenAI({
    //apiKey: "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",
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
});

//messages: [{ role: 'user', content: request }],
export async function getAiResponse(request) {
    const chatCompletion = await openai.chat.completions.create({
        model: 'gpt-3.5-turbo',
        messages: [
            { role: "user", content: request }
        ],
        temperature: 0.2
    });
    return chatCompletion.choices[0].message.content;
}

export async function getAiResponseGPT4(request) {
    const chatCompletion = await openai.chat.completions.create({
        model: 'gpt-4-1106-preview',
        messages: [
            { role: "user", content: request }
        ],
        temperature: 0.2
    });
    return chatCompletion.choices[0].message.content;
}

